import Awesomplete from 'awesomplete';
import axios from 'axios';

var init = function() {
  var acResult = []
  var acTerm = ""
  var searchInput = document.getElementById("t");
  var searchAws = new Awesomplete(searchInput, {
    list: [],
    sort: false
  });
  searchInput.addEventListener('awesomplete-selectcomplete',function(){
    axios
      .post("/search/acLog", {
        options: acResult,
        search_term: acTerm,
        selected_index: acResult.findIndex((option) => option === this.value),
      })
      .catch((err) => console.error(err));
    document.getElementById("search-form").submit(); // Submit search form
  });

  searchInput.oninput = function() {
    acTerm = this.value;
    if (acTerm.length > 1) {
      axios
        .get("/search/ac", {
          params: {
            t: acTerm
          }
        })
        .then(function(response) {
          acResult = response.data ? response.data : [];
          searchAws.list = acResult;
        });
    }
  };
  searchInput.focus();
};
window.addEventListener("load", init, false);
